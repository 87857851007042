.form-group {
    margin-bottom: 0.5rem;
}

/*.btn-primary {*/
/*    background-color: #FF5728;*/
/*    border-color: #FF5728;*/
/*}*/

/*.btn-primary:disabled {*/
/*    background-color: #FF5728;*/
/*    border-color: #FF5728;*/
/*}*/

/*.btn-primary:hover {*/
/*    background-color: #F67D08;*/
/*    border-color: #F67D08;*/
/*}*/

/*.btn-secondary {*/
/*    background-color: #FFF;*/
/*    color: #3b3e40;*/
/*    border-color: #cccccc;*/
/*}*/

/*.btn-secondary:hover {*/
/*    background-color: #F4FAFF;*/
/*    border-color:#ACB6C6;*/
/*    color: #292b2c;*/
/*}*/
/*.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {*/
/*    background-color: #FF5728;*/
/*    border-color: #FF3315;*/
/*}*/